<template>
  <div id="app">
    <div class="column column__left">
      <div class="column__content">
        <h1 class="header">
          <span class="header__emphasis">Arkadiusz Wańdoch</span>Kancelaria Radcy Prawnego
        </h1>
        <p class="address">ul. Mścibora 70/10<br/>61-062 Poznań</p>

        <img class="mobile-pic" src="./assets/profile.png" alt="Arkadiusz Wańdoch"/>

        <p class="profile">
          <span class="profile__header">Radca prawny</span>
          <span class="profile__content">Arkadiusz Wańdoch</span>
        </p>
        <p class="contact">
          <span class="contact__phone">tel. 693 808 402</span>
          <a href="mailto:kancelaria@wandoch.pl" class="contact__mail">kancelaria@wandoch.pl</a>
        </p>
        <footer class="footer">od 2011 r.</footer>
      </div>
    </div>
    <div class="column column__right column--vertical-center">
      <img class="profile-pic" src="./assets/profile.png" alt="Arkadiusz Wańdoch"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700;800;900&display=swap');

$main-color:  #5d4432;
$accent-color: #9b5726;

html {
  font-size: 2.5vh;
}

body, html, #app {
  padding: 0;
  margin: 0;
  background: #efede5;
  color: $main-color;
  font-family: 'Playfair Display', serif;
  height: 100%;

}

.mobile-pic {
  display: none;
}

.header {
  font-weight: bold;
  line-height: 2.2rem;
  &__emphasis {
    color: $accent-color;
    display: block;
  }
  &--alt {
    color: #988c81;
    text-transform: uppercase;
  }
}

.address {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.profile {
  padding-top: 8vh;
  &__header {
    color: #988c81;
    display: block;
    font-weight: 900;
    text-transform: uppercase;
  }
  &__content {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.contact {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.6rem;
  &__phone {
    display: block;
  }
  &__mail {
    border-bottom: 1px solid #d2cbc1;
    cursor: pointer;
    color: $main-color;
    text-decoration: none;
    transition: 0.5s all;
    &:hover {
      border-bottom-color: transparent;
      color: $accent-color;
    }
  }
}

.profile-pic {
  height: 70%;
}

#app {
  display: flex;
  justify-content: center;
}

.column {
  &--vertical-center {
    
  }
  padding: 0 4vh;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.footer {
  font-weight: bold;
}

@media (max-width: 800px) {
  #app {
    display: block;
    //justify-content: center;
  }
  .mobile-pic {
    margin: auto;
    display: block;
    width: 60%;
    max-width: 500px;
  }
  .column {
    align-items: flex-start;
  }
  .column__left {
    justify-content: space-around;
  }
  .column__right {
    display: none;
  }
  .footer {
    padding-bottom: 1rem;
  }
}
</style>
